@charset "UTF-8";
.preview-cover-image {
  width: 100%;
  position: relative;
  background: center center no-repeat;
  background-size: cover;
  background-image: url(../../assets/img/default.png);
  border-radius: 8px; }
  .preview-cover-image:before {
    content: "";
    display: block;
    padding-top: 60%; }
  .preview-cover-image:after {
    content: "更换封面";
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    border-radius: 8px; }
  .preview-cover-image:hover:after {
    display: flex;
    cursor: pointer; }
