@import "../../assets/scss/variables.scss";
/*
* toast
*/
.Toastify__toast {
  border-radius: 8px !important;
  &--warning {
    background-color: $warning-color !important;
  }
  &--success {
    background-color: $success-color !important;
  }
  &--default {
    background: $default-color !important;
    color: #fff !important;
  }
}
.Toastify__progress-bar {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.1)
  ) !important;
  &--default {
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    ) !important;
  }
}
.Toastify__toast-body {
  font-size: 14px;
}
