.ad-item {
  .ad-item-content {
    width: calc(100% - 126px);
    h4 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
