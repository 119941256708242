$primary-color: #fb6340;
@import "node_modules/loaders.css/src/animations/ball-beat.scss";

.page-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 4rem);
}

.loader-hidden {
  display: none;
}
.loader-active {
  display: block;
}
