.FroalaEditorImgWrapper {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  padding: 60% 0 0 0;
  cursor: pointer;
}
img.fr-view.fr-dii {
  display: inline-block;
  float: none;
  vertical-align: center;
  margin: auto;
  width: 100%;
  max-width: 100%;
  border-radius: 8px;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
  object-fit: cover;
  display: block;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.fr-image-resizer {
  border: solid 1px #00cff4;
  border-radius: 8px;
}
