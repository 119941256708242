body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.border-l {
  border-radius: 0.8rem;
}

.border-top-l {
  border-radius: 0.8rem 0.8rem 0 0 !important;
}

.border-bottom-l {
  border-radius: 0 0 0.8rem 0.8rem !important;
}

.main-card {
  min-height: calc(100vh - 3rem);
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.table .max-300-text {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table .max-200-text {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table .max-100-text {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  border-color: #11cdef;
  background-color: #11cdef;
}

.fr-box.fr-basic .fr-element {
  min-height: calc(100vh - 200px);
}
.fr-box.fr-basic.fr-top .fr-wrapper {
  box-shadow: none;
}

.dropzone-multiple .dz-message {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.dz-message {
  z-index: 999;
  padding: 5rem 1rem;
  cursor: pointer;
  transition: all 0.15s ease;
  text-align: center;
  color: #8898aa;
  border: 1px dashed #dee2e6;
  border-radius: 0.375rem;
  background-color: #fff;
  order: -1;
}

.preview-image {
  width: 100px;
  margin: 10px;
  position: relative;
  background: center center no-repeat;
  background-size: contain;
  border-radius: 8px;
  overflow: hidden;
  background-color: #eee;
}
.preview-image:before {
  content: "";
  display: block;
  padding-top: 100%;
}

.preview-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.preview-body {
  width: calc(100% - 120px);
}

.f-12 {
  font-size: 12px;
}
.f-14 {
  font-size: 14px;
}

.form-control {
  color: #172b4d;
}

.custom-control-label span {
  position: relative;
  top: 0px;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #11cdef;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #11cdef;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #11cdef;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #11cdef;
  box-shadow: none;
}

.close {
  font-size: 14px;
}

label {
  font-size: 14px;
  color: #32325d;
}

.list-ad-image {
  width: 80px;
  height: 80px;
  background: center center no-repeat;
  background-size: contain;
}

.category-tree ul {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}
.category-tree .custom-control {
  width: 120px;
}

.navbar-vertical .navbar-nav .nav-link {
  font-size: 1rem;
}
