@import "../../assets/scss/variables";
.affix {
  position: fixed;
  z-index: 99;
}

.post_save_bar.affix {
  top: 0;
  bottom: auto;
}

@media (max-width: 991px) {
  .post_save_bar.affix {
    bottom: 0;
    top: auto;
  }
}

/*
* REACT SELECT
*/
.form-control-alternative:focus {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.react-select__option--is-selected {
  color: white !important;
  background-color: #5e72e4 !important;
}

// .react-select__option:hover {
//   background-color: rgba(94, 114, 228, 0.60);
// }

.react-select__menu,
.react-select__menu-list {
  color: #525f7f !important;
  border: 0 !important;
  border-radius: 0.4375rem !important;
  box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1),
    0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
}

.argon-select-container {
  line-height: 1.5;
  font-size: 0.875rem;
  .react-select__control {
    transition: box-shadow 0.15s ease !important;
    border: 0 !important;
    border-radius: 0.375rem;
    min-height: calc(2.75rem + 2px) !important;
    box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
    &--is-focused {
      border: 0 !important;
      box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
        0 1px 3px rgba(0, 0, 0, 0.08);
    }
  }
  .react-select__input {
    color: #212529;
    font-size: 0.875rem;
  }
  .react-select__placeholder {
    color: #212529 !important;
    font-size: 0.875rem;
    font-weight: bold;
  }
  .react-select__indicator-separator {
    width: 0px !important;
    background-color: transparent !important;
  }
  .react-select__single-value {
    color: #212529 !important;
  }
}

.primaryBtn-select-container {
  line-height: 1.5;
  font-size: 0.875rem;

  .react-select__control {
    transition: box-shadow 0.15s ease !important;
    border: 0 !important;
    border-radius: 0.375rem;
    min-height: calc(2.75rem + 2px) !important;
    background-color: #5e72e4 !important;
    box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
    &--is-focused {
      border: 0 !important;
      box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
        0 1px 3px rgba(0, 0, 0, 0.08);
    }
  }
  .react-select__value-container {
    justify-content: center !important;
  }
  .react-select__indicators {
    display: none;
  }
  .react-select__input {
    color: #fff !important;
    font-size: 0.875rem;
  }
  .react-select__placeholder {
    color: $placeholder-color !important;
    font-size: 0.875rem;
  }
  .react-select__indicator-separator {
    width: 0px !important;
    background-color: transparent !important;
  }
  .react-select__single-value {
    color: #fff !important;
  }
}

.react-select-container {
  line-height: 1.5;
  font-size: 0.875rem;

  .react-select__control {
    transition: box-shadow 0.15s ease !important;
    border: 0 !important;
    border-radius: 0.375rem;
    min-height: calc(2.75rem + 2px) !important;
    &--is-focused {
      border: 0 !important;
      box-shadow: none;
    }
  }
  .react-select__input {
    color: $default-color;
    font-size: 0.875rem;
    font-weight: 600;
  }
  .react-select__placeholder {
    color: $default-color !important;
    font-size: 0.875rem;
    font-weight: 600;
  }
  .react-select__indicator-separator {
    width: 0px !important;
    background-color: transparent !important;
  }
  .react-select__single-value {
    color: $default-color !important;
    font-weight: 600;
  }
  .react-select__dropdown-indicator {
    color: $default-color !important;
  }
}

.outline-select-container {
  line-height: 1.5;
  font-size: 0.875rem;
  .react-select__control {
    transition: box-shadow 0.15s ease !important;
    // border: 0 !important;
    border-color: $light-color;
    border-radius: 0.375rem;
    min-height: calc(2.75rem + 2px) !important;
    // box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
    &--is-focused {
      // border: 0 !important;
      border-color: $focus-color !important;
      box-shadow: none;
    }
  }
  .react-select__input {
    color: #212529;
    font-size: 0.875rem;
  }
  .react-select__placeholder {
    color: $placeholder-color !important;
    font-size: 0.875rem;
  }
  .react-select__indicator-separator {
    width: 0px !important;
    background-color: transparent !important;
  }
  .react-select__single-value {
    color: #212529 !important;
  }
}

.btn-white {
  color: #212529;
  border-color: #fff;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
}

.collapse-dropdown {
  position: relative;
  .collapse-dropdown-menu {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 50px;
    box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
    border-radius: 0.375rem;
    z-index: 99;
  }
}
