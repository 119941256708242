/*
* toast
*/
.Toastify__toast {
  border-radius: 8px !important; }
  .Toastify__toast--warning {
    background-color: #fb6340 !important; }
  .Toastify__toast--success {
    background-color: #2dce89 !important; }
  .Toastify__toast--default {
    background: #172b4d !important;
    color: #fff !important; }

.Toastify__progress-bar {
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)) !important;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)) !important; }
  .Toastify__progress-bar--default {
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)) !important;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)) !important; }

.Toastify__toast-body {
  font-size: 14px; }

.affix {
  position: fixed;
  z-index: 99; }

.post_save_bar.affix {
  top: 0;
  bottom: auto; }

@media (max-width: 991px) {
  .post_save_bar.affix {
    bottom: 0;
    top: auto; } }

/*
* REACT SELECT
*/
.form-control-alternative:focus {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }

.react-select__option--is-selected {
  color: white !important;
  background-color: #5e72e4 !important; }

.react-select__menu,
.react-select__menu-list {
  color: #525f7f !important;
  border: 0 !important;
  border-radius: 0.4375rem !important;
  box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1), 0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1); }

.argon-select-container {
  line-height: 1.5;
  font-size: 0.875rem; }
  .argon-select-container .react-select__control {
    -webkit-transition: box-shadow 0.15s ease !important;
    transition: box-shadow 0.15s ease !important;
    border: 0 !important;
    border-radius: 0.375rem;
    min-height: calc(2.75rem + 2px) !important;
    box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02); }
    .argon-select-container .react-select__control--is-focused {
      border: 0 !important;
      box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }
  .argon-select-container .react-select__input {
    color: #212529;
    font-size: 0.875rem; }
  .argon-select-container .react-select__placeholder {
    color: #212529 !important;
    font-size: 0.875rem;
    font-weight: bold; }
  .argon-select-container .react-select__indicator-separator {
    width: 0px !important;
    background-color: transparent !important; }
  .argon-select-container .react-select__single-value {
    color: #212529 !important; }

.primaryBtn-select-container {
  line-height: 1.5;
  font-size: 0.875rem; }
  .primaryBtn-select-container .react-select__control {
    -webkit-transition: box-shadow 0.15s ease !important;
    transition: box-shadow 0.15s ease !important;
    border: 0 !important;
    border-radius: 0.375rem;
    min-height: calc(2.75rem + 2px) !important;
    background-color: #5e72e4 !important;
    box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02); }
    .primaryBtn-select-container .react-select__control--is-focused {
      border: 0 !important;
      box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }
  .primaryBtn-select-container .react-select__value-container {
    -webkit-justify-content: center !important;
            justify-content: center !important; }
  .primaryBtn-select-container .react-select__indicators {
    display: none; }
  .primaryBtn-select-container .react-select__input {
    color: #fff !important;
    font-size: 0.875rem; }
  .primaryBtn-select-container .react-select__placeholder {
    color: #adb5bd !important;
    font-size: 0.875rem; }
  .primaryBtn-select-container .react-select__indicator-separator {
    width: 0px !important;
    background-color: transparent !important; }
  .primaryBtn-select-container .react-select__single-value {
    color: #fff !important; }

.react-select-container {
  line-height: 1.5;
  font-size: 0.875rem; }
  .react-select-container .react-select__control {
    -webkit-transition: box-shadow 0.15s ease !important;
    transition: box-shadow 0.15s ease !important;
    border: 0 !important;
    border-radius: 0.375rem;
    min-height: calc(2.75rem + 2px) !important; }
    .react-select-container .react-select__control--is-focused {
      border: 0 !important;
      box-shadow: none; }
  .react-select-container .react-select__input {
    color: #172b4d;
    font-size: 0.875rem;
    font-weight: 600; }
  .react-select-container .react-select__placeholder {
    color: #172b4d !important;
    font-size: 0.875rem;
    font-weight: 600; }
  .react-select-container .react-select__indicator-separator {
    width: 0px !important;
    background-color: transparent !important; }
  .react-select-container .react-select__single-value {
    color: #172b4d !important;
    font-weight: 600; }
  .react-select-container .react-select__dropdown-indicator {
    color: #172b4d !important; }

.outline-select-container {
  line-height: 1.5;
  font-size: 0.875rem; }
  .outline-select-container .react-select__control {
    -webkit-transition: box-shadow 0.15s ease !important;
    transition: box-shadow 0.15s ease !important;
    border-color: #cad1d7;
    border-radius: 0.375rem;
    min-height: calc(2.75rem + 2px) !important; }
    .outline-select-container .react-select__control--is-focused {
      border-color: rgba(50, 151, 211, 0.25) !important;
      box-shadow: none; }
  .outline-select-container .react-select__input {
    color: #212529;
    font-size: 0.875rem; }
  .outline-select-container .react-select__placeholder {
    color: #adb5bd !important;
    font-size: 0.875rem; }
  .outline-select-container .react-select__indicator-separator {
    width: 0px !important;
    background-color: transparent !important; }
  .outline-select-container .react-select__single-value {
    color: #212529 !important; }

.btn-white {
  color: #212529;
  border-color: #fff;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02); }

.collapse-dropdown {
  position: relative; }
  .collapse-dropdown .collapse-dropdown-menu {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 50px;
    box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
    border-radius: 0.375rem;
    z-index: 99; }

.ad-item .ad-item-content {
  width: calc(100% - 126px); }
  .ad-item .ad-item-content h4 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

@charset "UTF-8";
.dz-preview-single .preview-image {
  width: 100%;
  margin: auto;
  cursor: pointer; }
  .dz-preview-single .preview-image:before {
    padding-top: 60%; }
  .dz-preview-single .preview-image:after {
    content: "\70B9\51FB\66F4\6362\56FE\7247";
    color: #fff;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0;
    top: 0;
    display: none; }
  .dz-preview-single .preview-image:hover:after {
    display: -webkit-flex;
    display: flex; }

@charset "UTF-8";
.preview-cover-image {
  width: 100%;
  position: relative;
  background: center center no-repeat;
  background-size: cover;
  background-image: url(/static/media/default.27ae13fd.png);
  border-radius: 8px; }
  .preview-cover-image:before {
    content: "";
    display: block;
    padding-top: 60%; }
  .preview-cover-image:after {
    content: "\66F4\6362\5C01\9762";
    display: none;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    border-radius: 8px; }
  .preview-cover-image:hover:after {
    display: -webkit-flex;
    display: flex;
    cursor: pointer; }

.FroalaEditorImgWrapper {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  padding: 60% 0 0 0;
  cursor: pointer; }

img.fr-view.fr-dii {
  display: inline-block;
  float: none;
  vertical-align: center;
  margin: auto;
  width: 100%;
  max-width: 100%;
  border-radius: 8px;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
  object-fit: cover;
  display: block;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.fr-image-resizer {
  border: solid 1px #00cff4;
  border-radius: 8px; }

@-webkit-keyframes ball-beat {
  50% {
    opacity: 0.2;
    -webkit-transform: scale(0.75);
            transform: scale(0.75); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes ball-beat {
  50% {
    opacity: 0.2;
    -webkit-transform: scale(0.75);
            transform: scale(0.75); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); } }

.ball-beat > div {
  background-color: #fb6340;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  margin: 2px;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  display: inline-block;
  -webkit-animation: ball-beat 0.7s 0s infinite linear;
          animation: ball-beat 0.7s 0s infinite linear; }
  .ball-beat > div:nth-child(2n-1) {
    -webkit-animation-delay: -0.35s !important;
            animation-delay: -0.35s !important; }

.page-center {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: calc(100vh - 4rem); }

.loader-hidden {
  display: none; }

.loader-active {
  display: block; }

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.border-l {
  border-radius: 0.8rem;
}

.border-top-l {
  border-radius: 0.8rem 0.8rem 0 0 !important;
}

.border-bottom-l {
  border-radius: 0 0 0.8rem 0.8rem !important;
}

.main-card {
  min-height: calc(100vh - 3rem);
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.table .max-300-text {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table .max-200-text {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table .max-100-text {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  border-color: #11cdef;
  background-color: #11cdef;
}

.fr-box.fr-basic .fr-element {
  min-height: calc(100vh - 200px);
}
.fr-box.fr-basic.fr-top .fr-wrapper {
  box-shadow: none;
}

.dropzone-multiple .dz-message {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.dz-message {
  z-index: 999;
  padding: 5rem 1rem;
  cursor: pointer;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  text-align: center;
  color: #8898aa;
  border: 1px dashed #dee2e6;
  border-radius: 0.375rem;
  background-color: #fff;
  -webkit-order: -1;
          order: -1;
}

.preview-image {
  width: 100px;
  margin: 10px;
  position: relative;
  background: center center no-repeat;
  background-size: contain;
  border-radius: 8px;
  overflow: hidden;
  background-color: #eee;
}
.preview-image:before {
  content: "";
  display: block;
  padding-top: 100%;
}

.preview-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.preview-body {
  width: calc(100% - 120px);
}

.f-12 {
  font-size: 12px;
}
.f-14 {
  font-size: 14px;
}

.form-control {
  color: #172b4d;
}

.custom-control-label span {
  position: relative;
  top: 0px;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #11cdef;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #11cdef;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #11cdef;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #11cdef;
  box-shadow: none;
}

.close {
  font-size: 14px;
}

label {
  font-size: 14px;
  color: #32325d;
}

.list-ad-image {
  width: 80px;
  height: 80px;
  background: center center no-repeat;
  background-size: contain;
}

.category-tree ul {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  list-style-type: none;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
}
.category-tree .custom-control {
  width: 120px;
}

.navbar-vertical .navbar-nav .nav-link {
  font-size: 1rem;
}

