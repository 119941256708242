@charset "UTF-8";
.dz-preview-single .preview-image {
  width: 100%;
  margin: auto;
  cursor: pointer; }
  .dz-preview-single .preview-image:before {
    padding-top: 60%; }
  .dz-preview-single .preview-image:after {
    content: "点击更换图片";
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0;
    top: 0;
    display: none; }
  .dz-preview-single .preview-image:hover:after {
    display: flex; }
